<template>
  <b-row>
    <b-col :cols="field.cols ? field.cols : 12" :md="field.md ? field.md : 4" :lg="field.lg ? field.lg : 3"
      :xl="field.xl ? field.xl : 2" v-for="field in fields" :key="field.key">
      <slot :name="field.key" :field="field">
        <b-form-group :label="$t(field.label)" :label-for="field.key" v-if="!field.hide">
          <b-form-input :ref="field.key" v-if="field.type === 'text'" :id="field.key" v-model="data[field.key]"
            :name="field.key" :placeholder="$t(field.label)" @change="onChange" />

          <n-tel-input v-if="field.type === 'tel'" v-model="data[field.key]" :ref="field.key" :id="field.key"
            :name="field.key" :placeholder="$t(field.label)" @input="onChange"></n-tel-input>

          <n-currency-input :ref="field.key" v-if="field.type === 'currency'" :id="field.key" v-model="data[field.key]"
            :name="field.key" :placeholder="$t(field.label)" :prefix="field.prefix" :suffix="field.suffix"
            @input="onChange"></n-currency-input>

          <b-form-datepicker v-if="field.type === 'date'" :ref="field.key" v-model="data[field.key]" :name="field.key"
            class="mb-1" :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }" v-bind="labels[$i18n.locale] || {}" :locale="$i18n.locale" :hide-header="true" />

          <n-date-picker v-if="field.type === 'date-picker'" :ref="field.key" :name="field.key"
            :placeholder="$t(field.label)" :disabled="field.disabled" :disable-type="field.disableType"
            v-model="data[field.key]" />

          <n-year-picker v-if="field.type === 'year-picker'" :ref="field.key" :name="field.key"
            :placeholder="$t(field.label)" :disabled="field.disabled" :disable-type="field.disableType"
            v-model="data[field.key]" />

          <n-year-range-picker v-if="field.type === 'year-range-picker'" :ref="field.key" :name="field.key"
            :placeholder="$t(field.label)" :disabled="field.disabled" :disable-type="field.disableType"
            v-model="data[field.key]" />

          <n-date-range-picker v-if="field.type === 'date-range-picker'" :ref="field.key" :name="field.key"
            :placeholder="$t(field.label)" :disabled="field.disabled" :disable-type="field.disableType"
            v-model="data[field.key]" />

          <n-async-multi-select v-if="field.type === 'asyn-multi-selection'" v-model="data[field.key]" :ref="field.key"
            :name="field.key" :init-options="data[field.key]" :repository="field.repository"
            :selection-key="field.selectionKey" :selection-label="field.selectionLabel"
            :filter-field="field.filterField" :readonly="field.disabled" :localization="field.localization"
            @change="onChange"></n-async-multi-select>

          <n-async-single-select :ref="field.key" v-if="field.type === 'asyn-single-selection'"
            v-model="data[field.key]" :name="field.key" :init-options="data[field.key]" :repository="field.repository"
            :selection-key="field.selectionKey" :selection-label="field.selectionLabel" :clearable="field.clearable"
            @change="onChange"></n-async-single-select>

          <n-single-select v-if="field.type === 'single-selection'" v-model="data[field.key]" :ref="field.key"
            :name="field.key" :init-options="data[field.key]" :options="field.options"
            :selection-key="field.selectionKey" :selection-label="field.selectionLabel" :readonly="field.disabled"
            :translatable="field.translatable" :clearable="field.clearable" @input="onChange"></n-single-select>

          <n-multi-select v-if="field.type === 'multi-selection'" v-model="data[field.key]" :ref="field.key"
            :name="field.key" :init-options="data[field.key]" :options="field.options"
            :selection-key="field.selectionKey" :selection-label="field.selectionLabel" :readonly="field.disabled"
            :translatable="field.translatable" @input="onChange"></n-multi-select>

          <b-form-radio-group :ref="field.key" v-if="field.type === 'radio'" :id="field.key" v-model="data[field.key]"
            :disabled="field.disabled" @change="onChange" buttons button-variant="outline-primary rounded-0"
            class="flex-wrap">
            <b-form-radio :value="option.value" v-for="option in field.options" :key="option.value">{{ $t(option.text)
              }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
  import {
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BFormDatepicker,
  } from "bootstrap-vue";
  import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
  import NAsyncMultiSelect from "@/components/NAsyncMultiSelect";
  import NSingleSelect from "@/components/NSingleSelect";
  import NMultiSelect from "@/components/NMultiSelect";
  import NDateInput from "@/components/NDateInput";
  import NCurrencyInput from "@/components/NCurrencyInput";
  import NTelInput from "@/components/NTelInput";
  import NYearPicker from "@/components/NYearPicker";
  import NDatePicker from "@/components/NDatePicker";
  import NYearRangePicker from "./NYearRangePicker.vue";
  import NDateRangePicker from "./NDateRangePicker.vue";

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BFormRadioGroup,
      BFormRadio,
      BRow,
      BCol,
      NAsyncSingleSelect,
      NDateInput,
      BFormDatepicker,
      NSingleSelect,
      NCurrencyInput,
      NAsyncMultiSelect,
      NTelInput,
      NYearPicker,
      NDatePicker,
      NYearRangePicker,
      NMultiSelect,
      NDateRangePicker,
    },
    props: {
      value: {},
      fields: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data() {
      return {
        data: {},

        labels: {
          en: {
            labelHelp: "",
          },
          km: {
            labelNoDateSelected: "មិនទាន់ជ្រើសរើសថ្ងៃ",
            labelHelp: "",
          },
        },
      };
    },
    computed: {},
    mounted() {
      if (this.value) {
        this.data = this.value;
      }
      console.log({ field: this.fields, dd: this.value });
    },
    methods: {
      reset() {
        this.fields.forEach((element) => {
          if (
            this.$refs[element.key] &&
            typeof this.$refs[element.key][0].reset === "function"
          ) {
            this.$refs[element.key][0].reset();
          }
          this.data[element.key] = null;
        });
      },
      onChange() {
        this.$emit("input", this.data);
      },
    },
  };
</script>

<style scoped></style>
